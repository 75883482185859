import { blrBounds } from "src/app/utils/service-region-BLR";
import { ncrBounds } from "src/app/utils/service-region-NCR";

export const featureList = {
    
        serviceRegions : {
          1: 'Delhi-NCR',
          2: 'Bangalore'
        },

        defaultActiveRideTypes : [
            {name: 'Scheduled Ride', code: 'SCHEDULED_RIDE', value:'SCHEDULED_RIDE', isActive: false, isRecurring: false, url: "assets/schedule-ride-hero-image.png", packageRequired: false, isDropRequired: true, isAirportRide: false, slotsRequired: false, tncRequired: false, isMultistopRide: false, isExpressRide: false},
            {name: 'Rental Rides', code: 'SCHEDULED_RENTALS', value:'SCHEDULED_RENTALS',isActive: false, isRecurring: false, url: "assets/rental-ride-hero-image.png", packageRequired: true, isDropRequired: false, isAirportRide: false, slotsRequired: false, tncRequired: true, isMultistopRide: false, isExpressRide: false },
            {name: 'Airport Ride', code: 'SCHEDULED_RIDE', value:'AIRPORT_RIDE',isActive: false, isRecurring: false, url: "assets/airport-ride-hero-image.png", packageRequired: false, isDropRequired: true, isAirportRide: false, slotsRequired: false, tncRequired: false, isMultistopRide: false, isExpressRide: false},
            {name: 'Shared Rides', code: 'SHARED_RIDE', value:'SHARED_RIDE', isActive: false, isRecurring: false, url: "assets/schedule-ride-hero-image.png",  packageRequired: false, isDropRequired: true, isAirportRide: false, slotsRequired: false, tncRequired: false, isMultistopRide: false, isExpressRide: false},
          ],

          
        rideTypeFeatures :{
          SCHEDULED_RIDE : {isPremiumAirportEnabled: true, isExpressRideEnabled: true},
          SCHEDULED_RENTALS : {isPremiumAirportEnabled: false, isExpressRideEnabled: false},
          SHARED_RIDE : {isPremiumAirportEnabled: false, isExpressRideEnabled: false},
        },

        carVariants : [
            {carUrl : 'assets/classic-india.png',
              carLabel: 'Tata Tigor Express T', 
              category: 'CLASSIC', 
              extraPoints: [
              {url: 'assets/space.svg', label: 'Spacious Sedan'}, 
              {url: 'assets/comfortable.svg', label: 'Comfortable Cab'}, 
              {url: 'assets/noiseless.svg', label: 'Noiseless Cab'}
              ], 
              priceMapId : 0,
              slotsRequired: false,
             country: 'IN', 
             isActive: false,
            extraInfo: []},
        
        //2  
            {carUrl : 'assets/premium-india.png', 
            carLabel: 'MG Hector | BYD',
            category: 'PREMIUM',
            slotsRequired: true,
            extraPoints: [
              {url: 'assets/luggage.svg', label: 'More luggage Space'},
              {url: 'assets/comfort.svg', label: 'Lavish comfort'},
              {url: 'assets/leg.svg', label: 'More leg space'}
              ],
             priceMapId : 0,
             country: 'IN',
             isActive: false,
            extraInfo: ['Switch to classic and enjoy an economical ride', 'Upgrade to experience enhanced comfort and premium service']
          }],

        defaultCoordinates: { lat: 28.4950, lng: 77.0895 },
        zoneBasedDefaultCoordinates : {1: { lat: 28.4950, lng: 77.0895 }, 2: { lat: 12.9716, lng: 77.5946 }},

        defaultPaymentModes:{
          'BUSINESS_ACCOUNT':{isActive: true, iconUrl: 'assets/business_wallet_icon.svg', desc: 'pay in advance seamlessly', title: 'Business Wallet', code: 'BUSINESS_ACCOUNT'},
          'CREDIT_CARD': {isActive: false, iconUrl: 'assets/card_icon.svg', desc: 'Pay through debit or credit card', title: 'Card', code: 'CREDIT_CARD'},
          'CASH' : {isActive: false, iconUrl: 'assets/cash_icon.svg', desc: 'pay at the end of the ride', title: 'Cash', code: 'CASH'}
        },

        defaultActivePaymentMode: {isActive: true, iconUrl: 'assets/business_wallet_icon.svg', desc: 'pay in advance seamlessly', title: 'Business Wallet', code: 'BUSINESS_ACCOUNT'},

        defaultCallingCode: {
          label: 'India',
          value: '+91',
          flag: "🇮🇳",
          limit: 10
        },

        defaultCurrency : {
          code: '₹',
          type: 'PREFIX'
        },

        maxCreateRideTimeStamp : 30,


    homepage: {
      news: true,
      externalLinks : true,
      expolre: true,
      pricing: true,
      serviceRegion: true,
      faq: true
    },


    bounds : {1: ncrBounds , 2: blrBounds}
}